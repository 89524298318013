import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

export function UeberMich() {
  return (
    <>
      <div className="d-flex align-items-center mt-5">
        <StaticImage
          width={400}
          src="../../../images/sabrina.jpg"
          alt="Sabrina"
          placeholder="blurred"
          className="rounded mx-auto"
        />
      </div>
      <p className="fw-bold fst-italic mt-5">Infos zu mir SABRINA:</p>
<p>
    <strong>Infos zu mir SABRINA:</strong>
</p>
<p>
    Hi - mein Name ist Sabrina Lackner und man könnte sagen „krisenbehaftete
    Zeiten“ wurden zu meinem „Spezialgebiet“, weil irgendwie begleiten sie mich
    schon mein ganzes Leben lang!
</p>
<p>
    Aufgewachsen in einer Familie, die schon früh geprägt war von schwierigen
    Lebenssituationen, die dadurch immer wieder mein Weltbild aus den Angeln
    gehoben haben! Doch schon bereits im Kindesalter kristallisierte sich schon
    sehr früh für mich die Rolle der „Zuhörerin“, der „Vermittlerin“, der
    „Lösungsfinderin“ heraus. Ich erlernte es sozusagen seit Kindesbeinen
    Krisen zu überwinden und Chancen daraus zu kreieren. Da es sich schon sehr
    früh als mein Spezialgebiet abzeichnete wurde daraus meine sogenannte
    „Lebensaufgabe“ sprich Krisen zu bewältigen und hinter jeder Krise auch die
    Chance bzw. das Wachstumspotenzial zu erkennen!!!
</p>
<p>
    Ich kann die unzähligen Momente schon gar nicht mehr zählen, wo ich
    verzweifelt war und vor einer neuen Krise stand und das Gefühl hatte mich
    verloren zu haben! Ich möchte nicht behaupten ein Heilmittel gegen Krisen
    gefunden zu haben oder dass sie mich nicht mehr begleiten… Nein, sie
    gehören nach wie vor immer wieder zu meinem Leben dazu, aber sie wurden
    weniger, ich lernte damit umzugehen und ich habe das Wichtigste daraus
    gelernt „die Verbindung zu mir“ und „liebevoll und achtsam“ mit mir
    umzugehen und für mich einzustehen! Meine Grenzen wahrzunehmen und sowie
    auch immer wieder neu zu ziehen, war auch eine sehr wichtige Lernaufgabe
    für mich.
</p>
<p>
    Aufgrund der vielen schwierigen Momenten, war es für mich nie eine Option
    aufzugeben, ganz im Gegenteil meine Suche nach Bewältigungs- und
    Überlebensstrategien begann....
</p>
<p>
    ......Ich machte mich auf den Weg zu meiner ganz persönlichen Reise......
</p>
<p>
    Dadurch hat sich in den letzten ca. 20 Jahren Persönlichkeitsentwicklung
    ein riesengroßer Pot aus Lebenserfahrung, Wissen, Beratungstools sowie an
    energetischen Tools angesammelt, welche ich jetzt seit über 10 Jahre mit
    den Menschen teile.
</p>
<p>
    Angetrieben von meinem Perfektions- und Leistungsanspruch, versuchte ich
    mich dadurch stetig weiterzuentwickeln und weiterzubilden, um für mich,
    mein Umfeld und für meine Klienten, die besten Entwicklungs-/ und
    Bewältigungsstrategien sowie Tools anbieten zu können. Durch diese
    Ansprüche hätte ich fast die Kraft und das Wachstumspotenzial von Fehlern
    und Verwundbarkeit übersehen. Welche für mich persönlich zu sogenannten
    „Wunderwaffen“ geworden sind.
</p>
<p>
    Mittlerweile verbinde ich alles miteinander und ich selbst schöpfe auch
    tagtäglich daraus! Denn ich liebe es Persönlichkeitsentwicklung &amp;
    Energiearbeit miteinander zu verbinden, weil es für mich 2 weitere
    „Superkräfte“ sind die miteinander Berge versetzen können!
</p>
<p>
    <strong>
        „Sei der Stern nach dem die Menschen suchen. Wenn du das nicht sein
        kannst, sei der Fehler den sie brauchen.“
    </strong>
    (Zitat: Jeffrey Kastenmüller)
</p>
<p>
    <strong><em>Ausbildungen:</em></strong>
</p>
<p>
    2023 – 2018 Bahar Yilmaz &amp; Jeffrey Kastenmüller:
</p>
<p>
    „Superhuman Training 365, Empower yourself, Unkarma, Chakra Training, Life
    Force Training, New Spirit Coach, Elevation Coach, Limitless
    Intensivausbildung“
</p>
<p>
    2022/21 Organo SL – Organetik (Bioresonanzverfahren), Seminare A – D
</p>
<p>
    „Zertifizierte Organetikerin“
</p>
<p>
    2018/17 BFI – „Diplomlehrgang Case Management“
</p>
<p>
    2016 „Heilung des Inneren Kindes“ - Seminarzentrum für Bewusstseinsbildung
    Shekaina
</p>
<p>
    2015 „Lerne dein Kraftier kennen“ - Sophia Winkler Lebens- und
    Sozialberaterin
</p>
<p>
    2015/14 „Channelingausbildung“ - Seminarzentrum für Bewusstseinsbildung
    Shekaina
</p>
<p>
    2013 „Psychosomatik-Seminar“ - Seminarzentrum für Bewusstseinsbildung
    Shekaina
</p>
<p>
    2013 – 2010 „Ausbildung zum Lebens- und Sozialberater“ - Gesellschaft/
    Akademie für Lebens- und Sozialberatung Tirol bei Fr. DDr. Bánffy
    (entspricht der Gewerbeberechtigung BGBl. I 140/2003 und BGBl. II Nr.
    112/2006)
</p>
<p>
    Teilnahme an weiteren diversen Workshops, Seminaren, Fortbildungen und
    Aufstellungen:Diverse Biblio- und Psychodramen, Familienaufstellungen,
    Zeitplanung und Selbstmanagement, Gesprächsführung und Konfliktlösungen,
    Gender &amp; Diversity, Kinesiologie
</p>
<p>
    Berufliche Erfahrungen:
</p>
<p>
    2013 – lfd.
</p>
<p>
    eigene Praxis für Beratungen für Erwachsene, Kinder und Familien
</p>
<p>
    2015-2013
</p>
<p>
    Projekt „Sternenstaub“ für Kinder mit Verlusterfahrungen gemeinsam mit DI
    Brigitte Fink Lebens- und Sozialberaterin
</p>
<p>
    1997 – lfd.
</p>
<p>
    Diverse Berufserfahrungen: Abgeschlossene kaufm. Ausbildung + jahrelange
    Büromitarbeiterin in div. Bereichen, Personalberaterin, ambulante
    Familienbetreuung, Jugendcoach
</p>
    </>
  )
}
