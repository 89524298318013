import * as React from 'react';
import { Layout } from '../components/layout/Layout';
import { UeberMich } from '../components/pages/ueber-mich/UeberMich';

export default function UeberMichPage () {
  return (
    <Layout>
      <UeberMich/>
    </Layout>
  );
}
